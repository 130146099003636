import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import CareersBanner from '../components/CareersBanner';
import CareersContent from '../components/CareersContent';

const CareersPage = ({
  data: {
    contentfulCareers: { title, heading, image, metaDescription },
    allContentfulCompany: { edges },
    allCareersPost: { nodes },
  },
}) => {
  return (
    <Layout title={title} description={metaDescription}>
      <main>
        <CareersBanner name={title} heading={heading} image={image} />
        <CareersContent images={edges} items={nodes} />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query CareersQuery {
    contentfulCareers {
      title
      slug
      heading
      image {
        gatsbyImageData(width: 1920, height: 600)
        title
      }
      metaDescription
    }
    allContentfulCompany {
      edges {
        node {
          image {
            url
            title
            mimeType
          }
          companyId
        }
      }
    }
    allCareersPost {
      nodes {
        benefits
        company
        companyID
        dateCreated
        datePosted
        description
        displaySalary
        id
        industry
        industryID
        link
        location
        positionID
        qualifications
        title
        vacancyID
        vacancyType
        vacancyTypeID
      }
    }
  }
`;

export default CareersPage;

export const Head = ({
  data: {
    contentfulCareers: { title, metaDescription },
  },
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
    </>
  );
};
