import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandFonts,
  breakpointSizes,
  fluidFontSize,
  minBreakpointQuery,
  standardColours,
} from '../styles';
import { Container } from './ui';
import Breadcrumbs from './Breadcrumbs';

const StyledCareersBanner = styled.div`
  overflow: hidden;
  position: relative;

  ${minBreakpointQuery.large`
    overflow: unset;
  `}
`;

const StyledContainer = styled(Container)`
  position: relative;
  padding: 0;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  color: ${standardColours.white};
`;

const StyledOutter = styled.div`
  display: grid;
`;

const StyledHeading = styled.h2`
  ${fluidFontSize(
    '20px',
    '50px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  font-family: ${brandFonts.tertiary};
  color: ${standardColours.white};
  grid-area: 1 / 1 / 1 / 1;
  z-index: 2;
  width: 100%;
  max-width: 732px;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 30px 20px;

  ${minBreakpointQuery.medium`
    margin-left: 60px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 90px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 120px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  grid-area: 1 / 1 / 1 / 1;
  width: 100%;
  height: auto;
  min-height: 300px;
`;

const CareersBanner = ({
  heading,
  image: { gatsbyImageData, title },
  name,
}) => {
  return (
    <StyledCareersBanner>
      <StyledContainer wide={true}>
        <StyledBreadcrumbs currentPageTitle={name} />
        <StyledOutter>
          <StyledHeading>{heading ? heading : title}</StyledHeading>
          <StyledImage image={gatsbyImageData} alt={title} title={title} />
        </StyledOutter>
      </StyledContainer>
    </StyledCareersBanner>
  );
};

export default CareersBanner;
