import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  fontSize,
  fontWeights,
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  standardTransition,
  brandFonts,
  visuallyHidden,
} from '../styles';
import { Button, Container } from './ui';
import { slugify } from '../utils';
import Card from './Card';
import downArrow from '../images/down-arrow.svg';
import filterTick from '../images/filter-tick.svg';

const StyledCareersContent = styled.div``;

const StyledContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledOuter = styled.div`
  display: flex;
  gap: 30px;
  overflow: hidden;

  ${maxBreakpointQuery.medium`
    flex-direction: column;
  `}

  ${minBreakpointQuery.large`
    gap: 60px;
  `}
`;

const StyledFilterWrapper = styled.div`
  width: 100%;

  ${minBreakpointQuery.medium`
    min-width: 350px;
    max-width: 480px;
  `}
`;

const StyledFilterButton = styled.button`
  position: relative;
  border: none;
  border-right: solid 1px ${brandColours.quaternary};
  background-color: ${brandColours.tertiary};
  height: 88px;
  padding: 26px 34px;
  display: flex;
  align-items: center;
  width: 100%;

  &:after {
    content: '';
    display: block;
    background: url(${downArrow}) no-repeat;
    height: 12px;
    width: 18px;
    position: absolute;
    right: 42px;
    transition: ${standardTransition('transform')};

    ${({ display }) => {
      if (!display) {
        return css`
          transform: rotate(-90deg);
        `;
      }
    }}
  }
`;

const StyledInputWrapper = styled.div`
  ${({ display }) => {
    if (display) {
      return css`
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 36px 40px;
        background-color: ${brandColours.quinary};
        border: solid 1px ${brandColours.quaternary};
        border-left: none;
        max-height: 300px;
        overflow-y: auto;
      `;
    } else {
      return css`
        display: none;
      `;
    }
  }}
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  appearance: none;
  background-color: #fff;
  margin: 0;
  height: 30px;
  margin-right: 15px;
  position: relative;

  &:before {
    content: '';
    background-color: ${brandColours.tertiary};
    border: solid 1px ${brandColours.quaternary};
    font-size: 0;
    padding: 14px;
  }

  &:checked:before {
    background-color: ${brandColours.primary};
    border: solid 1px ${brandColours.senary};
    transition: ${standardTransition('background-color')};
  }

  &:after {
    content: '';
    display: none;
    background: url(${filterTick}) no-repeat;
    height: 14px;
    width: 18px;
    position: absolute;
    top: 7px;
    left: 7px;
  }

  &:checked:after {
    display: block;
    transition: ${standardTransition('display')};
  }
`;

const StyledInputText = styled.p`
  font-family: ${brandFonts.secondary};
  font-weight: ${fontWeights.regular};
`;

const StyledInner = styled.div`
  display: grid;
  gap: 36px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 0;
  height: fit-content;

  ${maxBreakpointQuery.medium`
    margin: 0 30px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: 1fr 1fr;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: 1fr;
    padding-right: 30px;

  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 1fr 1fr;
  `}

  ${minBreakpointQuery.xxlarge`
    grid-template-columns: 1fr;
    padding-right: 60px;
  `}
`;

const StyledCard = styled(Card)`
  position: relative;

  ${minBreakpointQuery.xxlarge`
    height: fit-content;
  `}
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  ${minBreakpointQuery.small`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
   margin-bottom: 30px;
  `}

  ${minBreakpointQuery.xlarge`
   margin-bottom: 35px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-bottom: 40px;
    grid-column-start: 1;
    grid-column-end: 3;
  `}

  ${maxBreakpointQuery.xxlarge`
    margin-top: 10px;
  `}
`;

const StyledDescription = styled.div`
  ul,
  ol {
    ${visuallyHidden()}
  }
  overflow: hidden;
  ${minBreakpointQuery.xxlarge`
    grid-column-start: 1;
    grid-column-end: 3;
  `}

  &:after {
    content: '...';
    display: inline-block;
    ${fontSize(18)};
  }
`;

const StyledLocation = styled.p`
  ${fontSize(18)}
  b {
    font-family: ${brandFonts.tertiary};
    margin-right: 6px;
  }
`;

const StyledSalary = styled.p`
  ${fontSize(18)}
  b {
    font-family: ${brandFonts.tertiary};
    margin-right: 6px;
  }
  margin-top: 5px;
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
  width: 204px;

  ${minBreakpointQuery.xxlarge`
    margin-left: auto;
    margin-bottom: auto;
    margin-top: 0;
    grid-row-start: 1;
    grid-column-start: 2;
  `}
`;

const CareersContent = ({ images, items }) => {
  // function to limit the length of a string
  const limit = (string = '', limit = 0) => {
    return string.substring(0, limit);
  };

  // function to retrieve a value from an object using a key
  const getValue = (object, key) => {
    return object[key];
  };

  // object to store company logos for display
  let logos = {};

  // loop through image data to add image data to logos object
  images.map(({ node: { image, companyId } }) => {
    return (logos[companyId] = { image: image });
  });

  const filters = [
    { filterId: 'location', title: 'Location' },
    { filterId: 'displaySalary', title: 'Salary range' },
    { filterId: 'industry', title: 'Job category' },
    { filterId: 'company', title: 'Business area' },
    { filterId: 'vacancyType', title: 'Vacancy Type' },
  ];

  // function to retrieve unique (remove all the dupes) values from a specific filter in the data and then sort them A -> Z
  const getFilterArray = filter => {
    const filtered = items.reduce((filteredData, item) => {
      const { [filter]: value } = item;
      if (
        value != null &&
        !filteredData.some(careerFilter => careerFilter[filter] === value)
      ) {
        filteredData.push({ [filter]: value });
      }
      return filteredData;
    }, []);
    return filtered.sort((a, b) => {
      const valueA = a[filter].toUpperCase();
      const valueB = b[filter].toUpperCase();
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
  };

  // state hook to manage displayed items
  const [displayedItems, setDisplayedItems] = useState(items);

  // state hook to manage which filters are currently displayed
  const [displayFilters, setDisplayFilters] = useState(
    filters.reduce(
      (careerFilter, { filterId }) => ({ ...careerFilter, [filterId]: false }),
      {}
    )
  );

  // function to handle filter changes
  const handleFilters = appliedFilters => {
    let filteredItems = items;
    for (const filterId in appliedFilters) {
      if (appliedFilters[filterId] !== 'All') {
        filteredItems = filteredItems.filter(
          item => item[filterId] === appliedFilters[filterId]
        );
      }
    }
    setDisplayedItems(filteredItems);
  };

  // state hook to manage currently applied filters
  const [appliedFilters, setAppliedFilters] = useState(
    filters.reduce(
      (careerFilter, { filterId }) => ({ ...careerFilter, [filterId]: 'All' }),
      {}
    )
  );

  // function to handle individual filter changes
  const handleFilterChange = (filterId, value) => {
    setAppliedFilters({ ...appliedFilters, [filterId]: value });
    handleFilters({ ...appliedFilters, [filterId]: value });
  };

  return (
    <StyledCareersContent>
      <StyledContainer wide={true}>
        <StyledOuter>
          <StyledFilterWrapper>
            {filters.map(({ filterId, title }) => (
              <div key={filterId}>
                <StyledFilterButton
                  onClick={() =>
                    setDisplayFilters({
                      ...displayFilters,
                      [filterId]: !displayFilters[filterId],
                    })
                  }
                  display={displayFilters[filterId]}
                >
                  {title}
                </StyledFilterButton>
                <StyledInputWrapper display={displayFilters[filterId]}>
                  <StyledLabel>
                    <StyledInput
                      type="radio"
                      name={filterId}
                      checked={appliedFilters[filterId] === 'All'}
                      onChange={() => handleFilterChange(filterId, 'All')}
                    />
                    <StyledInputText>All</StyledInputText>
                  </StyledLabel>
                  {getFilterArray(filterId).map(({ [filterId]: value }) => (
                    <StyledLabel key={value}>
                      <StyledInput
                        type="radio"
                        name={filterId}
                        checked={appliedFilters[filterId] === value}
                        onChange={() => handleFilterChange(filterId, value)}
                      />
                      <StyledInputText>{value}</StyledInputText>
                    </StyledLabel>
                  ))}
                </StyledInputWrapper>
              </div>
            ))}
          </StyledFilterWrapper>

          <StyledInner>
            {displayedItems &&
              displayedItems.length > 0 &&
              displayedItems.map(
                (
                  { companyID, description, displaySalary, location, title },
                  i
                ) => {
                  return (
                    <>
                      <StyledCard
                        isCareerCard={true}
                        image={
                          getValue(logos, companyID)
                            ? getValue(logos, companyID).image
                            : ''
                        }
                        heading={title}
                        key={i}
                      >
                        <StyledInfo>
                          <StyledLocation>
                            <b>Location:</b>
                            {location}
                          </StyledLocation>
                          <StyledSalary>
                            <b>Salary Details:</b>
                            {displaySalary}
                          </StyledSalary>
                        </StyledInfo>

                        <StyledDescription
                          dangerouslySetInnerHTML={{
                            __html: limit(
                              description.replace(/<[^>]*>?/gm, ''),
                              398
                            ),
                          }}
                        />

                        <StyledButton to={`/careers/${slugify(title)}`}>
                          View
                        </StyledButton>
                      </StyledCard>
                    </>
                  );
                }
              )}
          </StyledInner>
        </StyledOuter>
      </StyledContainer>
    </StyledCareersContent>
  );
};

export default CareersContent;
